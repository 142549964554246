/**
 * .o-aspect-ratio
 */

$object: 'aspect-ratio';

#{'.' + $global-objects-namespace + $object} {
  @extend .u-pos-relative;

  &:before {
    @extend .u-block;
    @extend .u-width-100pc;
    @extend %u-content-none;
  }

  > * {
    @extend .o-absfill;
  }

  &--16\:9 {
    &:before {
      padding-top: calc((9 / 16) * 100%);
    }
  }
}
