/**
 * .c-figures
 */

 $component: 'figures';
 #{'.' + $global-components-namespace + $component} {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gutter-unit);

    > * {
        flex-grow: 1;
        flex-basis: 1;
    }

    @media (min-width: 600px) {
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
    }

    &__figure {
        @extend %u-flex;
        @extend %u-fd-column-reverse;
        @extend %u-pv-2;
        @extend %u-ph-2;

        @extend .c-base;
    }

 }