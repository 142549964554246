// @keyframes animationName {
  
// }


body:not(.scroll-up) {
    .fade-in-section,
    .c-base,
    .c-callout,
    .c-info {
        opacity: 0;
        transform: translateY(20px); /* Moves the section down initially */
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        will-change: opacity, transform; /* Optimizes the transition */

        &.is-visible {
            opacity: 1;
            transform: translateY(0); /* Moves the section back into place */
        }
    }
}