/**
 * .o-type
 */

$object: 'type';
#{'.' + $global-objects-namespace + $object} {
    &-h0,
    &-h1,
    &-h2,
    &-h3,
    &-h4 {
        font-weight: 500;
    }

    &-h0 {
        font-size: clamp(28px, 4vw, 50px);
        line-height: calc(59/50);
    }

    &-h1 {
        font-size: clamp(22px, 4vw, 34px);
        line-height: calc(59/50);
    }

    &-h2 {
        font-size: clamp(19px, 4vw, 27px);
        line-height: 1.32;
    }

    &-h3 {
        font-size: clamp(18px, 4vw, 24px);
        line-height: 1.48;
    }

    &-h4 {
        font-size: clamp(16px, 4vw, 19px);
        line-height: 1.48;
    }

    &-body {
        font-size: clamp(16px, 4vw, 18px);
    }

    &-small {
        font-size: 14px;
    }

    &-xsmall {
        font-size: 13px;
    }
}

.u-def {
    text-decoration-style: dashed;
    text-decoration-color: lightgrey;
    text-decoration-thickness: 3px;
    text-decoration-line: underline;
    text-decoration-skip-ink: auto;
}

/* Base font size variables */
:root {
    --min-font-size: 16px; /* Minimum font size at the smallest breakpoint */
    --max-font-size: 24px; /* Maximum font size at the largest breakpoint */
    --min-vw: 320px;       /* Smallest viewport width */
    --max-vw: 1200px;      /* Largest viewport width */
  }
  
  /* Utility classes for scalable text */
  .scaled-font-small {
    font-size: clamp(
      var(--min-font-size),
      calc(var(--min-font-size) + (var(--max-font-size) - var(--min-font-size)) * ((100vw - var(--min-vw)) / (var(--max-vw) - var(--min-vw)))),
      var(--max-font-size)
    );
  }
  
  .scaled-font-medium {
    font-size: clamp(
      calc(var(--min-font-size) * 1.25),
      calc((var(--min-font-size) * 1.25) + (var(--max-font-size) * 1.25 - var(--min-font-size) * 1.25) * ((100vw - var(--min-vw)) / (var(--max-vw) - var(--min-vw)))),
      calc(var(--max-font-size) * 1.25)
    );
  }
  
  .scaled-font-large {
    font-size: clamp(
      calc(var(--min-font-size) * 1.5),
      calc((var(--min-font-size) * 1.5) + (var(--max-font-size) * 1.5 - var(--min-font-size) * 1.5) * ((100vw - var(--min-vw)) / (var(--max-vw) - var(--min-vw)))),
      calc(var(--max-font-size) * 1.5)
    );
  }
  