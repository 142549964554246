/**
 * .c-menu
 */

$component: 'menu';
#{'.' + $global-components-namespace + $component} {

    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 10;
    padding: 2rem;
    border-radius: 4px;
    border: 0px solid #F1F1F1;
    background: var(--mass-white, #FFF);
    box-shadow: 1px 1px 0px 0px rgba(17, 9, 61, 0.16), -1px -1px 0px 0px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    line-height: 1.45;
    letter-spacing: .005em;
    text-wrap: nowrap;
    overflow-y: auto;

    @media (max-width: 599px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;        
    }

    @media (min-width: 600px) {
        &__close-button {
            display: none !important;
        }
    }

    &__section-title {
        margin-bottom: .7rem;
        font-size: 1.2rem;
        font-weight: 500;
    }

    &__section-sub-title {
        font-size: .85rem;
        margin-bottom: .3rem;
        color: rgba($color-charkoal, .4);
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    &__contents {
        text-wrap: nowrap;
    }

    &__sub-section {
        flex-basis: 1; 
        flex-grow: 1; 
        background: rgba(0,0,0,.02); 
        padding: 1em; 
        border-radius: 6px;
    }

    &__list-item {

    }

    &__list-item--active {
        font-weight: 700;
        text-decoration: underline;
    }

}