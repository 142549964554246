@include make-utility(( alias: 'ml-auto', class: true, margin-left: auto));
@include make-utility(( alias: 'mr-auto', class: true, margin-right: auto));
@include make-utility(( alias: 'mh-auto', class: true, margin-left: auto, margin-right: auto));
@include make-utility(( alias: 'mv-auto', class: true, margin-top: auto, margin-bottom: auto));

.u-m-0 {
    margin: 0;
}

.u-m-children-0>* {
    margin: 0;
}

.u-mt-siblings-xxs>*+* {
    margin-top: 0.02rem;
}

.u-mt-siblings-xs>*+* {
    margin-top: 0.4rem;
}


.u-mt-siblings-s>*+* {
    margin-top: 1rem;
}

.u-mt-siblings-m>*+* {
    margin-top: 3rem;
}

.u-mt-siblings-l>*+* {
    margin-top: 6rem;
}

.u-mt-children-xl>* {
    margin-top: 7.5rem;
}

@for $value from 1 through 10 {
    @include make-utility((
        alias: 'mt-' + $value,
        margin-top: $value + rem,
        class: true,
    ));
}

@for $value from 1 through 5 {
    @include make-utility((
        alias: 'ml-' + $value + 'qem',
        margin-left: 'calc(' + $value + ' / 4em)',
        class: true,
    ));
}

// GAP
@for $value from 1 through 10 {
    @include make-utility((
        alias: 'gap-' + $value,
        gap: $value + rem,
        class: true,
    ));
}

@for $value from 1 through 5 {
    @include make-utility((
        alias: 'rowgap-' + $value,
        row-gap: $value + rem,
        class: true,
    ));
}

@for $value from 1 through 5 {
    @include make-utility((
        alias: 'columngap-' + $value,
        column-gap: $value + rem,
        class: true,
    ));
}
