/**
 * .c-process-container
 */

$component: 'process-container';


#{'.' + $global-components-namespace + $component} {

    // &__break {
    //     --gap: 10%;
    //     @extend %u-flex;
    //     gap: var(--gap);
    //     padding: 0 calc(var(--gap) / 4);
    //     align-items: center;

    //     &:before,
    //     &:after {
    //         content: '';
    //         flex-grow: 1;
    //         height: 1px;
    //         background-color: $color-matte;
    //         opacity: 0;
    //     }

    //     img {
            
    //     }
    // }

}

/**
 * .c-process
 */

$component: 'process';
#{'.' + $global-components-namespace + $component} {
    @extend %u-grid;
    gap: 2rem;
    --columns: 1;

    > * {
        // width: 100%;
        // flex-shrink: 0;
        // margin-top: 3rem;
        // max-width: calc(100% / 3);
    }

    &__phase {
        width: 100%;
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.4;

        > * {
            margin-top: .5rem;
        }
    }

    @media (min-width: 800px) {

        --columns: 2;

        grid-template-columns: repeat(var(--columns), 1fr);

    }

    @media (min-width: 1400px) {

        --columns: 4;

    }

    &--max-3col {

        @media (min-width: 800px) {

            --columns: 1;

        }


        @media (min-width: 1120px) {

            --columns: 3;
    
            grid-template-columns: repeat(var(--columns), 1fr);
    
        }
    
        @media (min-width: 1400px) {
    
            --columns: 3;
    
        }



    }

    

}