:root {

}

.t-light {

  // TO DO

}

.c-logo {
  // --color-grape: #6D1E6F; // replaced with light-mode colour
  // --dark-pink: #872363;
  // --color-crimson: #bf2b4b;
  // --purple: #6d1e6f;
  --red-purple: #b32a4d;

  --logo-icon-aspect-ratio: 100 / 94;

  @extend %u-flex;
  --logo-height: 2rem;
  height: var(--logo-height);
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--logo-height) / 4);

  &__icon,
  &__text {
    width: auto;
  }

  &__text {
    height: 77%;
  }

  .t-dark & {
    color: white;
  }
}


.c-logo__icon {
  display: grid;
  aspect-ratio: var(--logo-icon-aspect-ratio);
  overflow: hidden;
  width: auto;
  height: 100%;

  > * {
    grid-row: 1;
    grid-column: 1;
    width: 60.13%; /* approx 146/242 in % */
    aspect-ratio: 1;
    border-radius: 50%;
  }

  --color-br-donut-start: #872363;
  --color-br-donut-end: var(--color-ultramarine);

  --color-bl-donut-start: var(--color-grape);
  --color-bl-donut-end: var(--color-scarlet);

  .donut { 
    mask-image: url('/src/svg/mask.svg');
  }

  /* Bottom right donut */
  .donut--br {
    z-index: 1;
    place-self: end end;
    background:
      conic-gradient(
        from -90deg, 
        var(--color-br-donut-start),
        var(--color-br-donut-start) 10deg,
        var(--color-br-donut-end)
      );

    .t-dark & {
      --color-br-donut-end: var(--color-grape);
    }
  }

  /* Bottom left donut */
  .donut--bl--layer-1 {
    z-index: 2;
    align-self: end;
    background:
      conic-gradient(
        from 0deg,
        var(--color-grape),
        var(--color-scarlet) 345deg
      );
    rotate: 30deg;
  }

  /* Top center donut */
  .donut--t--layer-1 {
    z-index: 3;
    justify-self: center;
    background:
      conic-gradient(
        var(--color-crimson),
        var(--red-purple),
        var(--color-scarlet)
      );
    rotate: 210deg;
  }

  /* Bottom left half-donut */
  .donut--bl--layer-2 {
    z-index: 4;
    align-self: end;
    background:
      conic-gradient(
        var(--red-purple),
        var(--color-scarlet) 51%, /* >50% so no gaps */
        transparent 0
      );
    rotate: 210deg;
  }

  /* Top center half-donut */
  .donut--t--layer-2 {
    z-index: 5;
    justify-self: center;
    background:
      conic-gradient(
        var(--red-purple),
        var(--color-scarlet) 51%, /* >50% so no gaps */
        transparent 0
      );
    rotate: 30deg;
  }

  /* White ball inside a donut */
  .dot {
    --dot-size: 8%;
    z-index: 2;
    place-self: start center;
    background: radial-gradient(circle at 50% 17.5%, #fff var(--dot-size), #0000 calc(var(--dot-size) + .5px));
    rotate: -150deg;
    animation: 1.2s flume infinite linear 1s;

    --delay-one: .0s;
    --delay-two: .04s;
    --delay-three: .08s;

    &.dot-one {
      --delay: 0.0s;
      &__one {
        animation-delay: calc(var(--delay) + var(--delay-two));
        mix-blend-mode: overlay;
        opacity: 1;
      }
  
      &__two {
        animation-delay: calc(var(--delay) + var(--delay-two));
        mix-blend-mode: overlay;
        opacity: .4;
      }
  
      &__three {
        animation-delay: calc(var(--delay) + var(--delay-three));
        mix-blend-mode: overlay;
        opacity: .2;
      }
    }

    // &.dot-two {
    //   --delay: .1s;
    //   &__one {
    //     animation-delay: calc(var(--delay) + var(--delay-two));
    //     mix-blend-mode: overlay;
    //     opacity: 1;
    //   }
  
    //   &__two {
    //     animation-delay: calc(var(--delay) + var(--delay-two));
    //     mix-blend-mode: overlay;
    //     opacity: .4;
    //   }
  
    //   &__three {
    //     animation-delay: calc(var(--delay) + var(--delay-three));
    //     mix-blend-mode: overlay;
    //     opacity: .2;
    //   }
    // }
  }
}

@keyframes flume {
  16% {
    z-index: 5;
    rotate: 30deg;
  }

  // 16%

  32% {
    place-self: start center;
    rotate: 210deg;
    animation-timing-function: steps(1, end);
  }

  32.01% {
    place-self: end start;
    rotate: 30deg;
  }

  // 16%

  48% {
    rotate: -150deg;
  }

  // 11%

  59% {
    place-self: end start;
    rotate: -270deg;
    animation-timing-function: steps(1, end);
  }

  59.01% {
    z-index: 4;
    place-self: end end;
    rotate: -90deg;
  }

  // 16%

  75% {
    rotate: 90deg;
  }

  // 16%

  91% {
    place-self: end end;
    rotate: 270deg;
    animation-timing-function: steps(1, end);
  }

  91.01% {
    z-index: 1;
    place-self: start center;
    rotate: -150deg;
  }
}

.c-logo:not(:hover):not(:focus) .dot {
  animation-play-state: paused;
  opacity: 0;
}














// :root {
//     --color-scarlet: #f9372b;
// }

// .c-logo__icon {
//     display: grid;
//     aspect-ratio: 243 / 229;
//     overflow: hidden;

//     --outer-donut: 60.2%;
//     --inner-donut: 22.5%;
  
//     > * {
//       grid-row: 1;
//       grid-column: 1;
//       width: var(--outer-donut);
//       aspect-ratio: 1;
//       border-radius: 50%;
//     }
  
//     .donut {
//       background:
//         conic-gradient(
//           var(--color-start),
//           var(--color-end) calc(50.1%), /* >50% so no gaps */
//           transparent 0
//         );
//       -webkit-mask: radial-gradient(#0000 var(--inner-donut), #000 calc(var(--inner-donut) + 0.5px), #000);
//     }

//     .whole-br {
//       z-index: 1;
//       place-self: end end;
//       background:
//         conic-gradient(
//           #872363,
//           #6D1E6F 25%,
//           #261299 75%,
//           #872363 0
//         );

//         .t-dark & {
//             background:
//             conic-gradient(
//             #872363,
//             #6D1E6F 25%,
//             #6D1E6F 75%,
//             #872363 0
//             );

//         }
//     }

//     .half-bl-b {
//       --color-start: #6D1E6F;
//       --color-end: #b32a4d;
  
//       z-index: 2;
//       align-self: end;
//       rotate: 30deg;
//     }
  
//     .half-tp-t {
//       --color-start: #BF2B4B;
//       --color-end: #b32a4d;
  
//       z-index: 3;
//       justify-self: center;
//       rotate: 210deg;
//     }
  
//     .half-bl-t {
//       --color-start: #b32a4d;
//       --color-end: var(--color-scarlet);
  
//       z-index: 4;
//       align-self: end;
//       rotate: 210deg;
//     }
  
//     .half-tp-b {
//       --color-start: #b32a4d;
//       --color-end: var(--color-scarlet);
  
//       z-index: 5;
//       justify-self: center;
//       rotate: 30deg;
//     }
  
//     .dot {
//       z-index: 2;
//       will-change: rotate;
//       place-self: start center;
//       background: radial-gradient(
//         circle at 50% 17.5%,
//         #fff 8%,
//         transparent calc(8% + 1px) 0
//       );
//       rotate: -150deg;
//       animation: 1.5s flume infinite linear 1s;
//       transition: opacity .2s;

//       &.one {
//         animation-delay: .0s;
//         mix-blend-mode: overlay;
//         opacity: .7;
//       }

//       &.two {
//         animation-delay: .04s;
//         mix-blend-mode: overlay;
//         opacity: .4;
//       }

//       &.three {
//         animation-delay: .08s;
//         mix-blend-mode: overlay;
//         opacity: .2;
//       }
//     }

//     .c-logo:not(:hover):not(:focus) & > .dot {
//         animation-play-state: paused;
//         opacity: 0;
//     }

//   }
  
//   @keyframes flume {
//     16% {
//       z-index: 5;
//       rotate: 30deg;
//     }
  
//     32% {
//       place-self: start center;
//       rotate: 210deg;
//       animation-timing-function: steps(1, end);
//     }
  
//     32.01% {
//       place-self: end start;
//       rotate: 30deg;
//     }
  
//     48% {
//       rotate: -150deg;
//     }
  
//     59% {
//       place-self: end start;
//       rotate: -270deg;
//       animation-timing-function: steps(1, end);
//     }
  
//     59.01% {
//       z-index: 4;
//       place-self: end end;
//       rotate: -90deg;
//     }
  
//     75% {
//       rotate: 90deg;
//     }
  
//     91% {
//       place-self: end end;
//       rotate: 270deg;
//       animation-timing-function: steps(1, end);
//     }
  
//     91.01% {
//       z-index: 1;
//       place-self: start center;
//       rotate: -150deg;
//     }
//   }