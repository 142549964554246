@font-face {
    font-family: 'Aeonik';
    src: url('/src/font/aeonik/Aeonik-Air.woff2') format('woff2'),
        url('/src/font/aeonik/Aeonik-Air.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/src/font/aeonik/Aeonik-Black.woff2') format('woff2'),
        url('/src/font/aeonik/Aeonik-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/src/font/aeonik/Aeonik-BoldItalic.woff2') format('woff2'),
        url('/src/font/aeonik/Aeonik-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/src/font/aeonik/Aeonik-AirItalic.woff2') format('woff2'),
        url('/src/font/aeonik/Aeonik-AirItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/src/font/aeonik/Aeonik-Bold.woff2') format('woff2'),
        url('/src/font/aeonik/Aeonik-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/src/font/aeonik/Aeonik-BlackItalic.woff2') format('woff2'),
        url('/src/font/aeonik/Aeonik-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/src/font/aeonik/Aeonik-Thin.woff2') format('woff2'),
        url('/src/font/aeonik/Aeonik-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/src/font/aeonik/Aeonik-RegularItalic.woff2') format('woff2'),
        url('/src/font/aeonik/Aeonik-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/src/font/aeonik/Aeonik-MediumItalic.woff2') format('woff2'),
        url('/src/font/aeonik/Aeonik-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/src/font/aeonik/Aeonik-LightItalic.woff2') format('woff2'),
        url('/src/font/aeonik/Aeonik-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/src/font/aeonik/Aeonik-ThinItalic.woff2') format('woff2'),
        url('/src/font/aeonik/Aeonik-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/src/font/aeonik/Aeonik-Light.woff2') format('woff2'),
        url('/src/font/aeonik/Aeonik-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/src/font/aeonik/Aeonik-Medium.woff2') format('woff2'),
        url('/src/font/aeonik/Aeonik-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/src/font/aeonik/Aeonik-Regular.woff2') format('woff2'),
        url('/src/font/aeonik/Aeonik-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

:root {
    color: var(--color-charkoal);
    font-family: 'Aeonik', 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 16px;
    @extend %u-family-aeonik;
    text-rendering: geometricprecision;
}

body {
    
}