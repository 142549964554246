/**
 * .c-header
 */

$component: 'header';
#{'.' + $global-components-namespace + $component} {

    @extend %u-flex;
    @extend %u-jc-space-between;
    @extend %u-ai-flex-start;
    @extend %u-fw-wrap;
    @extend %u-gap-1;

    &__logo {
        @extend %u-flex;
        place-items: center;
        height: 3rem;
    }

    &__book-now {
        flex-wrap: wrap;
    }

    &__menu {

    }

    @media (max-width: 599px) {
        &__book-now {
            display: none;
        }

    }

    @media (min-width: 600px) {

        &__book-now {
            order: 2;
            margin-left: auto;
        }

        &__menu {
            order: 3;
        }

    }

}

@media (max-width: 599px) {

    .c-header__book-now {

        display: none;

    }

}