$font-family-properties: (
  'aeonik': 'Aeonik, Helvetica Neue, Helvetica, sans-serif',
);

@each $value-alias, $value in $font-family-properties {
  @include make-utility((
    alias: 'family-' + $value-alias,
    class: true,
    font-family: $value
  ));
}

@each $value in (bold, medium, light, 500) {
  @include make-utility((
    alias: 'weight-' + $value,
    class: true,
    font-weight: $value
  ));
}

@each $value in (center, left, right) {
  @include make-utility((
    alias: 'align-' + $value,
    class: true,
    text-align: $value
  ));
}

@each $value in (nowrap) {
  @include make-utility((
    alias: 'ws-' + $value,
    class: true,
    white-space: $value
  ));
}

@each $value in (uppercase) {
  @include make-utility((
    alias: 'text-' + $value,
    class: true,
    text-transform: $value
  ));
}

.u-line-medium {
  line-height: 1.45;
}

.u-letters-nano {
  letter-spacing: .005em;
}