/**
 * .c-header-menu
 */

$component: 'header-menu';

#{'.' + $global-components-namespace + $component} {

  @media (max-height: 180px) {
    // PartnersPage Video Embed
    display: none;
  }
  
}