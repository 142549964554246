/**
  * TODO
 */

$placeholder-library: '' !global;

@mixin makeUtilities($properties-settings) {

    // each property...
    @each $property-setting in $properties-settings {

        // property:
        $property-definition: ifNotError($property-setting, property, 'No "Property: "declared in settings!');
        
        $property: $property-definition;
        $property-class: $property-definition;
        
        // list
        @if (type_of($property-definition) == list) {
            $property: first($property-definition);
            $property-class: last($property-definition);
        }

        @if ($property-class != '') {
            $property-class: $property-class + '-';
        }
        
        // List values
        $valuesSettings: ifNotError($property-setting, values, 'No "Values: ()" declared in settings!');

        @each $value-setting in $valuesSettings {

            // Simple Values
            @if (type_of($value-setting) == list) {
                
                @each $value-definition in $value-setting {

                    $value: $value-definition;
                    $value-class: $value-definition;
                    
                    // Value has Class nickname
                    @if type_of($value-definition) == list {
                        $value: first($value-definition);
                        $value-class: last($value-definition);
                    }

                    // u-pos-abs;
                    $utility: $global-utilities-namespace + $property-class + $value-class;

                    // .u-pos-abs;
                    $selector: #{'.' + $utility};

                    // u-position-absolute;
                    $placeholder-id: $global-utilities-namespace + $property-class + $value-class;

                    // Create utility - and maybe a new %placeholder
                    @if (index($placeholder-library, $placeholder-id) != null) {
                        @include createUtilityFromPlaceholder($selector, $placeholder-id);
                    } @else {
                        @include createPlaceholderAndUtility($placeholder-id, $property, $value, $selector);
                    }
                }
            }

            // NOT Simple Values
            @if (type_of($value-setting) == map) {

                // Complex Value
                @if map-has-key($value-setting, value) {

                    $value-definition: map-get($value-setting, value);
                    $value: $value-definition;
                    $value-class: $value-definition;

                    // Value Class = Shortname
                    @if type_of($value-definition) == list {
                        $value: first($value-definition);
                        $value-class: last($value-definition);
                    }

                    $combinator-definition: '';
                    @if (map-has-key($value-setting, combinator)) {
                        $combinator-definition: map-get($value-setting, combinator);
                    }

                    $combinator: '';
                    $combinator-class: '';

                    @if (type_of($combinator-definition) == string) {
                        $combinator: $combinator-definition;
                    }

                    @if (type_of($combinator-definition) == list) {
                        $combinator: first($combinator-definition);
                        $combinator-class: last($combinator-definition) + '-';
                    }

                    // Define the utility
                    $utility: $global-utilities-namespace + $property-class + $value-class;
                    
                    // Define the selector
                    $selector: #{'.' + $global-utilities-namespace + $combinator-class + $property-class + $value-class + ' ' + $combinator};
                    
                    $placeholder-id: $global-utilities-namespace + $property-class + $value-class;

                    // Create utility - and maybe a new %placeholder
                    @if (index($placeholder-library, $placeholder-id) != null) {
                        @include createUtilityFromPlaceholder($selector, $placeholder-id);
                    } @else {
                        @include createPlaceholderAndUtility($placeholder-id, $property, $value, $selector);
                    }
                    
                    // Pseudos
                    @if (map-has-key($value-setting, pseudos)) {

                        @each $pseudo in map-get($value-setting, pseudos) {
                            
                            // Simple Pseudo
                            @if (type_of($pseudo) == string) {

                                // .u-color-link@hover:hover > *
                                $selector: #{'.' + $global-utilities-namespace + $combinator-class + $property-class + $value-class + $global-pseudo-separator + $pseudo + ':' + $pseudo + ' ' + $combinator};

                                // u-color-link
                                $placeholder-id: $global-utilities-namespace + $property-class + $value-class;
                
                                // Create utility - and maybe a new %placeholder
                                @if (index($placeholder-library, $placeholder-id) != null) {
                                    @include createUtilityFromPlaceholder($selector, $placeholder-id);
                                } @else {
                                    @include createPlaceholderAndUtility($placeholder-id, $property, $value, $selector);
                                }
                            }

                            // Combination pseudo (hoverfocus)
                            @if (type_of($pseudo) == list) {

                                $pseudoClass: strReplace(toString($pseudo), '" ', '');

                                @each $pseudoPart in $pseudo {

                                    $selector: #{'.' + $utility + $combinator-class + $global-pseudo-separator + $pseudoClass + ' ' +  + ':' + $pseudoPart};

                                    // Create utility - and maybe a new %placeholder
                                    @if (index($placeholder-library, $placeholder-id) != null) {
                                        @include createUtilityFromPlaceholder($selector, $placeholder-id);
                                    } @else {
                                        @include createPlaceholderAndUtility($placeholder-id, $property, $value, $selector);
                                    }
                                }
                            }
                        }
                    }

                    // Breakpoints
                    @if (map-has-key($value-setting, breakpoints)) {

                        @each $breakpoint in map-get($value-setting, breakpoints) {

                            $selector: #{'.' + $global-utilities-namespace + $combinator-class + $property-class + $value-class + $global-breakpoint-separator + $breakpoint + ' ' + $combinator};
                 
                            @include breakpoint($breakpoint) {
                                @include makeClass($selector, $property, $value);
                            }
                        }
                    }
                }

                // Placeholders
                @if (map-has-key($value-setting, placeholders)) {

                    @each $placeholder in map-get($value-setting, placeholders) {

                        $value: $placeholder;
                        $value-class: $placeholder;
                        
                        // Value has Class nickname
                        @if type_of($placeholder) == list {
                            $value: first($placeholder);
                            $value-class: last($placeholder);
                        }
            
                        $utility: $global-utilities-namespace + $property-class + $value-class;
                        
                        $selector: #{'.' + $utility};

                        $placeholder-id: $global-utilities-namespace + $property-class + $value-class;
                        
                        // Create %placeholder if it's new!
                        @if (index($placeholder-library, $placeholder-id) == null) {
                            @include createPlaceholder($placeholder-id) {
                                @each $property in $property {
                                    #{$property}: #{$value};
                                }
                            };
                            @include addToPlaceholderDefinitions($placeholder-id);
                        }
                    }
                }

                // Stepped
                @if (map-has-key($value-setting, start)) {

                    $start: map-get($value-setting, start);
                    $end: map-get($value-setting, end);
                    $step: 1;

                    @if (map-has-key($value-setting, step)) {
                        $step: map-get($value-setting, step);
                    }

                    $unit: '';
                    $unitClass: '';

                    @if (map-has-key($value-setting, unit)) {
                        $unit-definition: map-get($value-setting, unit);
                        
                        $unit: $unit-definition;
                        $unitClass: $unit-definition;

                        @if (type_of($unit-definition) == list) {
                            $unit: first($unit-definition);
                            $unitClass: last($unit-definition);
                        }
                    }

                    $combinator-definition: '';
                    @if (map-has-key($value-setting, combinator)) {
                        $combinator-definition: map-get($value-setting, combinator);
                    }

                    $combinator: $combinator-definition;
                    $combinator-class: '';

                    @if (type_of($combinator-definition) == list) {
                        $combinator: first($combinator-definition);
                        $combinator-class: last($combinator-definition) + '-';
                    }

                    $value: $start;
                    $value-class: ''; // do I need this?

                    @while $value <= $end {

                        $value-class: $value + $unitClass;

                        // Define the utility
                        $utility: $global-utilities-namespace + $property-class + $value-class;
                        $utility: strReplace(toString($utility), '.', '');

                        $selector: #{'.' + $global-utilities-namespace + $combinator-class + $property-class + $value-class + ' ' + $combinator};
                        
                        $value-with-unit: #{$value + $unit};
                        
                        // u-color-link
                        $placeholder-id: $global-utilities-namespace + $property-class + '-' + $value + $unitClass;
                
                        // Create utility - and maybe a new %placeholder
                        @if (index($placeholder-library, $placeholder-id) != null) {
                            @include createUtilityFromPlaceholder($selector, $placeholder-id);
                        } @else {
                            @include createPlaceholderAndUtility($placeholder-id, $property, $value-with-unit, $selector);
                        }

                        $value: $value + $step;
                    }
                }
            }
        }
    }
}
