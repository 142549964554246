
@each $value in ('auto', 'flex', 'inline-flex', 'grid', 'block', 'inline-block', 'none') {
  @include make-utility((
    alias: $value,
    class: true,
    display: unquote($value)
  ));
}

[x-cloak] { 
  display: none !important;
}