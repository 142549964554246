/**
 * .s-article
 */
    
$scoped: 'article';
#{'.' + $global-scoped-namespace + $scoped} {

    font-size: clamp(16px, 1vw, 18px);
    line-height: 1.45;

    > *:first-child {
        padding-top: 1rem;
    }

    > * + * {
        margin-top: 1rem;
    }

    > * + *:is(h1, h2, h3, h4, h5, h6, .o-type-h1, .o-type-h2, .o-type-h3, .o-type-h4) {
        margin-top: 1.5rem;
    }

    *:not(p) + p {
        // margin-top: 1rem;
    }

    p + *:not(p) {
        margin-top: 1.5rem;
    }

    > :last-child {
        margin-bottom: 1rem;
    }
    
    h1:not([class*="o-type"]) {
        margin-top: 1.4rem;
        @extend .o-type-h1;
        @extend %u-weight-500;
    }

    h2:not([class*="o-type"]) {
        @extend .o-type-h2;
    }

    h3:not([class*="o-type"]) {
        @extend .o-type-h3;
    }

    h4:not([class*="o-type"]) {
        @extend .o-type-h4;
        // @extend %u-weight-500;
    }

    u,
    ol {

        li {
            list-style-position: inside;

            + li {
                margin-top: .5em;
            }
        }
        
    }

    ol li {
        list-style-type: decimal;
        list-style-position: inside;

        + li {
            margin-top: .5em;
        }
    }

    ul li {
        list-style-type: disc;
    }

    ol li {
        list-style-type: decimal;

    }

    li li {
        margin-left: 1.5em;
    }

    a {
        font-weight: 500;
    }
}


/*
.img-zoomer-box {
    position: relative;

    &:hover,
    &:active {
        cursor: zoom-in;

        .img-2 {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.img-1 {
    opacity: 1 !important;
  transform: none !important;
}
  .img-2 {
    width: 340px;
    aspect-ratio: 1 / 1;
    background-repeat: no-repeat;
    background-color: #FFF;
    box-shadow: 0 5px 10px -2px rgba(0,0,0,0.3);
    pointer-events: none;
    position: absolute;
    opacity: 0;
    border: 4px solid whitesmoke;
    z-index: 99;
    border-radius: 100%;
    transition: opacity .1s, transform .3s;
    background-size: 300%;
    transform: scale(.7);
  }

*/

table {
    --border: 1px solid rgba(0, 0, 0, .1);
    // font-size: 13px;
    // white-space: nowrap;

    tr, thead {
        border-left: var(--border);
        border-bottom: var(--border);
    }
    td, th {
        padding: .8em;
        border-top: var(--border);
        border-right: var(--border);
    }

    th {
        font-weight: 600;
    }
}

.hljs-template-variable {
    color: #8541f9 !important;
}

pre {
    // border-radius: 6px; 
    // padding: .6rem .96rem; 
    border: 1px solid #9e9e9e;
    border-radius: 4px;
    box-shadow: inset 0 2px 2px rgba(0,0,0,.1);
    font-size: 12px; 
    line-height: 1.4;
}

code {

    border-radius: 6px; 

}

p,
li { 
    code {
        font-weight: 500;
        background-color: lightyellow;
        padding: .3em .4em;
    }
}

.c-caption {
    @extend .u-color-matte;
    font-size: 14px;
    padding: .4em 2em;
}

.hljs {
    background: transparent !important;
}

@media (min-width: 600px) {
    .c-text-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
        justify-content: space-between;

        > * {
            max-width: 600px;
        }
    }
}