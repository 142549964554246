/**
 * .c-button
 */

 $component: 'button';
 #{'.' + $global-components-namespace + $component} {

    display: flex;
    // flex-direction: column;
    align-items: center;
    text-align: center;
    width: max-content;
    height: 3rem;
    font-size: 1rem;
    @extend %u-ph-2;
    transition: background-color .2s ease, color .2s ease;

    &__details {
        @extend %u-ph-1;
        @extend %u-pv-1;
        font-size: .8rem;
        font-weight: 300;
        letter-spacing: .02em;
        @extend %u-align-center;

        .t-dark & {
            color: white;
        }
    }

    &--primary {
        @extend %u-bgcolor-grape;
        border-radius: 3px;
        box-shadow: inset 1px 1px 0 rgba($color-scarlet, .2);
        // transition: background-color .2s ease;
        @extend %u-color-white;

        &:hover,
        &:focus {
            @extend %u-color-white;
            @extend %u-bgcolor-crimson;
        }
    }

    &--on-grape {
        @extend %u-bgcolor-crimson;
        border-radius: 3px;
        box-shadow: inset 1px 1px 0 rgba($color-scarlet, .5), 0 1px 3px rgba($color-charkoal, .2);
        // transition: background-color .2s ease;
        @extend %u-color-white;

        &:hover,
        &:focus {
            @extend %u-color-white;
            @extend %u-bgcolor-scarlet;
        }
    }

    &--secondary {
        font-size: 1rem;
        border-radius: 3px;
        font-weight: normal;

        // &:hover,
        // &:focus {
        //     @extend %u-color-crimson;
        // }
        
    }

    &--reverse {
        font-size: 1rem;
        border-radius: 3px;
        font-weight: normal;
        color: white;
        background: linear-gradient(180deg, #261299 0%, #6D1E6F 100%);
    }

    .t-dark &--secondary {
        background-color: rgba($color-matte, .5);
        box-shadow: inset 1px 1px 0 rgba($color-white, .2);
        color: white;

        &:hover,
        &:focus {
            // box-shadow: inset 1px 1px 0 rgba($color-white, .4);
            background-color: rgba($color-matte, .8);
        }
    }

    .t-light &--secondary {
        background-color: rgba($color-matte, .2);
        @extend %u-color-ultramarine;
        @extend %u-weight-bold;

        &:hover,
        &:focus {
            @extend %u-color-crimson;
        }

    }
    
}

[data-fillout-id] button {
    @extend .c-button;
    @extend .c-button--primary;
}