/* Style for the link to look like regular text */
.phrase {
    color: inherit;
    text-decoration: none;
    position: relative;
    cursor: default;
    white-space: nowrap;
}

/* The actual clickable tooltip - restricts clickable area */
.tooltip {
    --width: .7em;
    transform: translateX(-4%);
    display: inline-flex;
    @extend %u-ai-center;
    @extend %u-jc-center;
    font-weight: 900;
    padding: 1rem;
    color: $color-ultramarine;
    cursor: pointer;
    text-decoration: none;
    font-size: 12px;
    vertical-align: super;
    width: var(--width);
    height: var(--width);
    border-radius: 100%;
    background-image: radial-gradient(
        circle at center,
        rgba(0,0,0,.1),
        rgba(0,0,0,.1) 40%,
        transparent 40%,
        transparent 100%
    );

    .phrase:hover & {
        color: $color-crimson;
    }
}