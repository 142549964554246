/**
 * .o-oversized
 */

$object: 'oversized';
#{'.' + $global-objects-namespace + $object} {
    margin-left: 50%;
    transform: translateX(-50%);
    @include breakpoint(uptoarticle3col) {
        width: 100%;
    }
    @include breakpoint(fromarticle3col) {
        width: gutterspans(9);
    }
    >* {
        max-width: 100%;
    }
}