html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, main, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0;
  outline: 0;
  font-size: 100%;
}

html,
body {
  min-height: 100%;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
  scroll-padding-top: 6rem;
}

:root {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes:none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  background: transparent;
  font-size: 100%;
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover,
  &:focus,
  &:active {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
  color: #000;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
}

button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  text-align: inherit;
}

label,
button,
select {
  cursor: pointer;
}

input,
select {
  vertical-align: middle;
}

input,
select,
textarea,
button {
  font: inherit;
  color: inherit;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

code {
  font: inherit;
}

*,
*:before,
*:after,
* * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
}