/**
 * .c-quote
 */

$component: 'quote';
#{'.' + $global-components-namespace + $component} {
    

    &__message {
        margin-top: 1rem;
        @extend .o-type-h3;
        border-left: .5rem solid var(--color-charkoal);
        padding-left: 2rem;
        line-height: 1.35;
        max-width: 70vw;
    }

    &__photo {
        width: 5rem;
    }

    &__identity {
        margin-top: 2rem;
    }

    &__meta {
        display: grid;
        gap: .4rem;
    }

    &__person {

        @extend %u-weight-500;
        
        font-size: 1.1rem;

    }

    &__role {
        @extend %u-color-matte;
    }

    &__rating {
        display: flex;
        align-items: center;
        gap: .5em;
        @extend %u-color-scarlet;
        font-size: .8em;
        letter-spacing: .03em;        
    }

    &__stars {
        font-size: .9em;
        letter-spacing: .07em;
    }

}