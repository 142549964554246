/**
 * .c-popover
 */

$component: 'popover';
#{'.' + $global-components-namespace + $component} {

    --border-radius: 6px;

    position: fixed;
    inset: 0;
    z-index: 999;
    background-color: rgba($color-charkoal, .89);
    display: grid;
    place-items: center;
    overflow-x: hidden;
    overflow-y: auto;

    &__container {

        width: 80%;
        height: 80%;
        max-width: 640px;
        border-radius: var(--border-radius);
        overflow-x: hidden;
        overflow-y: auto;
        background-color: var(--color-white);

        > * {

            width: 100%;
        
        }

    }

    iframe {

        border-radius: var(--border-radius);

    }
    
    // Autoheight

    &--autoheight {

    }

    &--autoheight &__container {
        // max-height: 80%;
        // height: max-content;

        height: unset;
        overflow: unset;
    }

    // Airtable

    &--airtable &__container > * {
        height: calc(100% + 24px);
    }

}