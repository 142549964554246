/**
 * .o-hide
 */

$object: 'hide';

#{'.' + $global-objects-namespace + $object} {
  @extend %u-z--1;
  @extend %u-pos-relative;
  @extend %u-ov-hidden;
  @extend %u-vis-hidden;
  @extend %u-events-none;
  @extend %u-clip-0;
}
