/**
 * .c-project-summary
 */

$component: 'project-summary';
#{'.' + $global-components-namespace + $component} {

    padding-top: 3rem;
    align-items: center;

    @media (max-height: 180px) {
        // PartnersPage Video Embed
        padding-top: 1rem;
    }

    &__overview {
        // padding-left: 2rem;
        // padding-top: 5rem;
    }

    &__label {
        letter-spacing: .005em;
    }

}
