/**
 * .c-base
 */

 $component: 'base';
 #{'.' + $global-components-namespace + $component} {

  @extend %u-bgcolor-off;
  border-radius: 8px;
  box-shadow: 
  1px 1px 0px 0px rgba(17, 9, 61, 0.06), 
  -1px 1px 0px 0px rgba(0, 0, 0, 0.05), 
  0px 4px 4px 0px rgba(0, 0, 0, 0.05);

}
