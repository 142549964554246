/**
 * .o-absfill
 */

$object: 'absfill';

#{'.' + $global-objects-namespace + $object} {
  @extend .u-pos-absolute;
  @extend .u-top-0;
  @extend .u-left-0;
  @extend .u-width-100pc;
  @extend .u-height-100pc;
}


/**
 * .o-abscentre
 */

$object: 'abscentre';

#{'.' + $global-objects-namespace + $object} {
  @extend .u-pos-absolute;
  @extend .u-top-50pc;
  @extend .u-left-50pc;
  @extend .u-trans--50pc;
}

/**
 * .o-abscentre-y
 */

$object: 'abscentre-y';

#{'.' + $global-objects-namespace + $object} {
  @extend .u-pos-absolute;
  @extend .u-top-50pc;
  @extend .u-transy--50pc;
}

/**
 * .o-abscentre-x
 */

$object: 'abscentre-x';

#{'.' + $global-objects-namespace + $object} {
  @extend .u-pos-absolute;
  @extend .u-left-50pc;
  @extend .u-transx--50pc;
}
