/**
 * .o-ellipsis
 * Sample component
 *
 * Used:
 * here, here and here.
 */

 $object: 'ellipsis';

 #{'.' + $global-objects-namespace + $object} {
   @extend %u-ws-nowrap;
   @extend %u-ov-hidden;
   text-overflow: ellipsis;
 }
