/* padding-top */
@each $value in (3rem) {
  @include make-utility((
    alias: 'pt-' + $value,
    padding-top: $value,
    class: true,
  ));
}

@for $value from 1 through 10 {
  @include make-utility((
    alias: 'pt-' + $value,
    padding-top: $value + rem,
    class: true,
  ));
}

/* padding-bottom */
@for $value from 1 through 10 {
  @include make-utility((
    alias: 'pb-' + $value,
    padding-bottom: $value + rem,
    class: true,
  ));
}

@include make-utility((
  alias: 'pb-05',
  padding-bottom: 0.5rem,
  class: true,
));

/* padding-vertical */
@for $value from 1 through 5 {
  @include make-utility((
    alias: 'pv-' + $value,
    padding-top: $value + rem,
    padding-bottom: $value + rem,
    class: true,
  ));
}

/* padding-horizontal */
@for $value from 1 through 5 {
  @include make-utility((
    alias: 'ph-' + $value,
    padding-right: $value + rem,
    padding-left: $value + rem,
    class: true,
  ));
}