/**
 * .c-info
 */

 $component: 'info';

 #{'.' + $global-components-namespace + $component} {

    display: flex;
    flex-direction: column;
    border-radius: 8px; 
    min-height: 280px;
    background-color: #CBE6F4;
    color: $color-marina;

    &__list {

        li {
            opacity: .5;
            display: inline-block;
            padding-right: .5rem;
            list-style-type: none;
            line-height: 1.6;
            letter-spacing: .03em;
            font-size: .9em;
            transition: opacity .8s ease;
            cursor: default;

            &:hover {
                opacity: .8;
            }
        }

        li:after {
            content: "";
        }
    }

    &__content {
        
        padding: 2rem 0; 

        @media (min-width: 1000px) {
            padding: 4rem 2rem; 
        }
    }


    &__background {
        @media (max-width: 1000px) {
            display: none;
        }

        &-frame {
            z-index: 1;
            width: 50%;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

 }