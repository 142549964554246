html, 
body {
  // background-color: #190D62;
}

html {
  height: 100%;

  &.no-scroll {
    overflow-y: hidden;
  }
}

body {
  min-height: 100%;
  overflow-x: hidden;

  @import 
  'vars-spacing',
  'vars-container';
}

a {
  color: var(--color-ultramarine);
  transition: color .2s ease;

  &:hover,
  &:focus {
    color: var(--color-crimson);
  }
}

a,
button {
  &:focus {
    outline: $color-scarlet solid 2px;
    outline-offset: .4rem;
  }
}

img {
  max-width: 100%;
}