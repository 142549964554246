/**
 * .c-callout
 */

$component: 'callout';
#{'.' + $global-components-namespace + $component} {

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    align-items: center;
    border-radius: 8px;   
    text-align: center;
    text-wrap: balance;
    min-height: 280px;

    > * {
        max-width: 80%;
    }

    &--grape {
        background-color: var(--color-grape);
        background-image: url('/src/svg/grape-circles.svg');
        background-blend-mode: screen;
        background-position: 50% 50%;
    }

}