/**
 * .c-pill
 */

 $component: 'pill';
 #{'.' + $global-components-namespace + $component} {
    color: #fff;
    text-transform: uppercase;
    border-radius: 3px;
    padding: 4px 7px;
    font-size: 12px;
    font-weight: 500;
    line-height:1;
    letter-spacing: 0.07ch;
    display:inline-block;

    background-color: $color-ultramarine;

    
    // background-color: $color-crimson;
    

    // &--2of3 {

    //     background-color: $color-grape;

    // }

    // &--2of4 {

    //     background-color: $color-grape;
        
    // }

    // &--3of4 {

    //     background-color: $color-marina;
        
    // }

    // &--last {

    //     background-color: $color-ultramarine;
        
    // }
 }