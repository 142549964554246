/**
 * .c-blink
 */

 $component: 'blink';
 #{'.' + $global-components-namespace + $component} {

    display: inline-block;
    width: 8px;
    margin-right: 6px;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    background-color: limegreen;

 }