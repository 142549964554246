/**
 * .o-container
 */

$object: 'container';

#{'.' + $global-objects-namespace + $object} {
  @extend .u-mh-auto;
  width: var(--container-width);
  max-width: 1440px;
}

/**
 * .o-twocolfrommid
 */

 $object: 'twocolfrommid';

 #{'.' + $global-objects-namespace + $object} {
  display: flex;
  gap: columnspans(2);

  &--gap-1 {
    gap: 1rem;
  }

  &--gap-2 {
    gap: 2rem;
  }

  &--gap-3 {
    gap: 3rem;
  }

  @media (max-width: 799px) {
    flex-direction: column;

    > * {
      width: 100%;
    }
  }

  @media (min-width: 800px) {
    
    justify-content: space-between;
  
    > * {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 50%;
    }

    > .s-article {
      padding-right: 3rem;
    }
  }
} 

/**
 * .o-twocolfromlarge
 */

 $object: 'twocolfromlarge';

 #{'.' + $global-objects-namespace + $object} {
  display: flex;
  gap: 4rem;
  
  &--gap-1 {
    gap: 1rem;
  }

  &--gap-2 {
    gap: 2rem;
  }

  &--gap-3 {
    gap: 3rem;
  }

  @media (max-width: 999px) {
    flex-direction: column;

    > * {
      width: 100%;
    }
  }

  @media (min-width: 1000px) {
    
    justify-content: space-between;
  
    > * {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 50%;
    }

    > .s-article {
      padding-right: 1rem;
    }
  }
} 

/**
* .o-swapatlarge
*/

$object: 'swapatlarge';

#{'.' + $global-objects-namespace + $object} {

  &__small {
    display: block;

    @media (min-width: 1500px) {
      display: none;
    }
  }

  &__large {
    display: none;

    @media (min-width: 1500px) {
      display: block;
    }
  }

} 