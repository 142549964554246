@mixin breakpoint($size, $breakpoints: $global-breakpoints) {
  $breakpoint-found: map-has-key($breakpoints, $size);

  @if ($breakpoint-found == true) {
    $breakpoint: map-get($breakpoints, $size);

    @media #{$breakpoint} {
      @content;
    }
  } @else {
    @warn "Breakpoint size " + #{$size} + " doesn't exist."
  }
}

/* Source: IotaCSS iota-breakpoint */
