/**
 * .c-tabs
 */

 $component: 'tabs';
 #{'.' + $global-components-namespace + $component} {

    .c-tabs {
        position: relative;
        overflow: auto;

        img {
            max-width: 100%;
            max-height: 40vh;
        }
    }

    .c-tab {

        &__label,
        &__content {
            padding: 1rem;
        }

        &__label {
            width: 100%;
            transition: background-color .4s ease, box-shadow .4s ease;

            &:hover,
            &:focus {
                @extend %u-color-ultramarine;
                @extend .c-base;
            }

            &--active {
                @extend .c-base;
                position: sticky;
                top: 0;
                @extend %u-color-ultramarine;
            }
    
        }
    
        &__content {
            @extend .c-base;
            @extend .s-article;

            .c-screenshot {
                margin-bottom: 2rem;
            }
        }
    }

 }

 #{'.' + $global-components-namespace + $component} {

    @media (max-width: 799px) {

        .c-tab__content {

            display: block !important;
        
        }

        &__label {
            width: 100%;
            transition: background-color .4s ease, box-shadow .4s ease;

            &:hover,
            &:focus {
                background: none;
                box-shadow: none;
            }
        }

    }

 }

 #{'.' + $global-components-namespace + $component} {

    @media (min-width: 800px) {

        display: grid;
        gap: 1rem;
        grid-template-rows: repeat(3, auto) 1fr;
        grid-auto-rows: 1fr;
        grid-template-columns: 1fr 4fr;
        // gap: 0px 0px;

        .c-tab__label {
            cursor: pointer;
            grid-column: 1;
        }
        
        .c-tab__content {
            grid-row: 1 / -1;
            grid-column: 2;

            padding: 2.5rem;
        }
    }

 }