/**
 * .c-screenshot
 */

 $component: 'screenshot';
 #{'.' + $global-components-namespace + $component} {

    border-radius: 9px;
    border: 6px solid white;
    box-shadow: 
        0 0 0 0.3px darkgrey,
        0 2px 4px rgba(0,0,0,.15);

 }