/**
 * .c-home
 */

.o-home-padding-t {
    padding-top: 6vmin;
}

.o-home-padding-b {
    padding-bottom: 6vmin;
}

.o-home-padding-y {
    @extend .o-home-padding-t;
    @extend .o-home-padding-b;
}

.o-home-padding-x {
    --contentWidth: 1440px;
    padding-right: max(2rem, 50vw - var(--contentWidth) / 2);
    padding-left: max(2rem, 50vw - var(--contentWidth) / 2);
}

.o-home-inset-x {
    --contentWidth: 1440px;
    right: max(2rem, 50vw - var(--contentWidth) / 2);
    left: max(2rem, 50vw - var(--contentWidth) / 2);
}

.c-home .c-header {
    @extend .o-home-padding-t;
    @extend .o-home-padding-x;
}

@media (min-width: 600px) {

    .c-home .c-menu {
        --contentWidth: 1440px;
        width: unset;
        left: max(2rem, 50vw - var(--contentWidth) / 2);
        right: max(2rem, 50vw - var(--contentWidth) / 2);

    }

}


 $component: 'home';
 #{'.' + $global-components-namespace + $component} {
    position: relative;
    @extend %u-bgcolor-marina;
    background-image: url('/src/svg/grape-circles.svg'), url('/src/svg/single-flat-gradient-circle.svg');
    background-blend-mode: screen, normal;
    background-position: 50% 5vw, 50% -50vw;
    background-size: 110vw, 130vw;
    background-repeat: no-repeat;

    cursor: default;

    @media (min-width: 1200px) {
        background-position: 50% -150px, 50% -800px;
        background-size: 1300px, 1700px;
    }

    &__statement {
        // margin-top: 12vmax;
        // margin-bottom: 20vmax;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        line-height: 1.45;
        text-align: center;
        text-wrap: balance;
        display: flex;
        justify-content: center;

        h1 {
            font-size: clamp(26px, 2vw + 1rem, 40px);
            font-weight: 500;
        }
    
        h2 {
            font-size: clamp(17px, 2vw + .5rem, 20px);
            font-weight: 300;
        }

    }

    &__mask {
        position: absolute;
        bottom: -1px;
        left: -1px;
        width: calc(100% + 2px);
        max-width: none;
    }

    .c-home__statement__book-now {
        margin-top: 2rem;
    }

    @media (max-width: 599px) {

        &__statement {
            padding-top: 16vmax;
            padding-bottom: 16vmax;
        }

    }

    @media (min-width: 600px) {

        &__statement {
            padding-top: 13vmax;
            padding-bottom: 26vmax;
        }

        .c-home__statement__book-now {
    
            display: none;
    
        }
    
    }


 }