@mixin addToPlaceholderDefinitions($utility) {
    $placeholder-library: append($placeholder-library, $utility) !global;
}

@mixin createPlaceholder($placeholder) {
    %#{$placeholder} {
        @content;
    }
}

@mixin extend($placeholder-id) {
    @extend %#{$placeholder-id};
}

@mixin createUtilityFromPlaceholder($selector, $placeholder-id) {
    #{$selector} {
        @include extend($placeholder-id);
    }
}

@mixin createPlaceholderAndUtility($placeholder-id, $property, $value, $selector) {
                    
    @include createPlaceholder($placeholder-id) {
        // Working I think
        @each $property in $property {
            #{$property}: #{$value};
        }
    };
    @include addToPlaceholderDefinitions($placeholder-id);
    @include createUtilityFromPlaceholder($selector, $placeholder-id);
}