/**
 * .c-case-study
 */

 $component: 'case-study';
 #{'.' + $global-components-namespace + $component} {
    padding-top: 6vmin;
    padding-bottom: 6vmin;
    background-color: $color-white;
    --contentWidth: 1440px;
    padding-right: max(2rem, 50vw - var(--contentWidth) / 2);
    padding-left: max(2rem, 50vw - var(--contentWidth) / 2);

    &__button {
        grid-area: button;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

 }