/**
 * .c-body
 */


$component: 'body';
#{'.' + $global-components-namespace + $component} {

    @extend .o-home-padding-y;
    background: white;
    -webkit-font-smoothing: antialiased;

}